import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import './ContactForm.css'



class ContactForm extends React.Component {

    constructor(props) {
      super(props);
      this.form = React.createRef();
      this.validate = this.validate.bind(this);
      this.onNameChange = this.onNameChange.bind(this)
      this.onEmailChange = this.onEmailChange.bind(this)
      this.onMessageChange = this.onMessageChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
      this.state = {
        name: '',
        email: '',
        message: ''
      }
    }

    
  render() {
   return(
     <div id="container">
        <div className="ContactForm">
        <form id="contact-form" ref={this.form} onSubmit={this.handleSubmit}>
            <label htmlFor="name">Uw naam</label>
            <input type="text" required value={this.state.name} onChange={this.onNameChange} />
            <label htmlFor="exampleInputEmail1">Emailadres</label>
            <input type="email" required  value={this.state.email} onChange={this.onEmailChange} />
            <label htmlFor="message">Bericht</label>
            <textarea required rows="5" value={this.state.message} onChange={this.onMessageChange} />
            <button type="submit" ref={btn => { this.btn = btn; }} >Versturen</button>
        </form>
        </div>
      </div>
   );
  }



  validate()
  {
    this.form.current.reportValidity();
  }

  onNameChange(event) {
    this.setState({name: event.target.value})
  }

  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  onMessageChange(event) {
    this.setState({message: event.target.value})
  }

  resetForm()
  {
    this.setState({name: '', email: '', message: ''})
  }
  
  handleSubmit(event) {
    event.preventDefault();
    if(!this.validate)
    {
      return;
    }
    this.btn.setAttribute("disabled", "disabled");
    console.log(this.state);

    axios({
      method: "POST", 
      url:"/api/contact", 
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success'){
        this.resetForm()
        this.props.history.push('/contact-succes')
      }else if(response.data.status === 'fail'){
        alert("Message failed to send.")
        this.btn.setAttribute("disabled", "false");
      }
    })
  }
}

export default withRouter(ContactForm);