import React from 'react';
import ContactForm from './ContactForm';
import Iframe from 'react-iframe'

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch
} from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobileAlt, faPhoneAlt, faWhatsApp } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import './App.css';


library.add(faEnvelope, faMobileAlt, faPhoneAlt,fab)


function App() {
  return (
    <Router>
      <div>
      <header>
        <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/sportprijzen">Sportprijzen</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
      </header>
      <div id="wrapper">
          <div id="inner">
      <section>
            <article>
                <div id="logo"></div>
                <Switch>
                <Route path="/sportprijzen">
                    <SportPrijzen />
                  </Route>
                  <Route path="/contact">
                    <ContactForm />
                  </Route>
                  <Route path="/contact-succes">
                    <ContactSucces />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
              </Switch>
            </article>
      </section>
      </div>
      </div>
      <footer>
        <Contact />
      </footer>
      </div>
    </Router>
  );
}


function Home() {
  return <div>
    <h1>Kwaliteit is betaalbaar</h1>
    <p>Ruiterslot is gespecialiseerd  in sportprijzen en ruitersportartikelen.</p>
    <p>Maak gebruik van onze zadelpasservice!</p>
    <p>We zijn dagelijks geopend van 15:00 tot 20:00 uur of op telefonische afspraak</p>
    <div id="stubben"></div>
    </div>
}

function SportPrijzen() {
  return <Iframe url="https://folder.wepublish.com/reefman/reefman-nl-2022-zp"
  width="80%"
  height="780px"
  frameborder="0"
  id="remasco-iframe"
  className="myClassname"
  display="initial"
  position="relative"
  styles={{border: "none"}} />
}

function Contact() {
  return <address>
  <ul>
    <li>Ruiterslot</li>
    <li>Breesegge 9</li>
    <li>7627LA Bornerbroek</li>
    <li><FontAwesomeIcon icon="mobile-alt" /> <FontAwesomeIcon icon={['fab', 'whatsapp']} /> 06-55802385 Ruitersport - <i>Herman Slot</i></li>
    <li><FontAwesomeIcon icon="mobile-alt" /> <FontAwesomeIcon icon={['fab', 'whatsapp']} /> 06-46244557 Sportprijzen - <i>Jannie Slot</i></li>
    <li><FontAwesomeIcon icon="envelope" /> <a href="mailto:info@ruiterslot.nl">info@ruiterslot.nl</a></li>
  </ul>
</address>
}

function ContactSucces() {
  return <div>Uw bericht is succesvol verstuurd</div>
}

export default App;
